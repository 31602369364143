@import url("https://fonts.googleapis.com/css?family=Open+Sans:regular,bold,italic&subset=latin,latin-ext");

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.organization {
  min-height: 100%;
  width: 100%;
  position: absolute;
  top: 0;
  left: 0;
  margin: 0;
  z-index: 1000;
  background: #f3f6ff;
  font-size: 1.6vh;
}

.organization > nav {
  height: 4vh;
  background: #f3f6ff;
  margin-bottom: 2vh;
  -webkit-box-shadow: 2px 2px 9px -3px rgba(66, 68, 90, 1);
  -moz-box-shadow: 2px 2px 9px -3px rgba(66, 68, 90, 1);
  box-shadow: 2px 2px 9px -3px rgba(66, 68, 90, 1);
  padding-top: 3vh;
  font-size: 2vh;
}

.container {
  width: 20vh;
  height: 20vh;
  display: flex;
  justify-content: space-between;
}

.pic-container {
  margin-right: 10px;
}

.pic1 {
  height: 5vh;
  width: auto;
}

.pic2 {
  height: 8vh;
  width: 8vh;
  border-radius: 50%;
}

.info-container {
  display: flex;
  align-items: center;
}

.map {
  height: 100vh;
  width: 100%;
}

.organization-design {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  background: white;
  padding: 2vh 3vh;
  margin: 1.7vh 1.7vh 10vh 1.7vh;
  -webkit-box-shadow: 2px 2px 9px -3px rgba(198, 199, 207);
  -moz-box-shadow: 2px 2px 9px -3px rgb(198, 199, 207);
  box-shadow: 2px 2px 9px -3px rgb(72, 73, 76);
  position: relative;
}

.button1 {
  display: inline-block;
  cursor: pointer;
  font-size: 1.7vh;
  line-height: 1;
  border-radius: 500px;
  transition-duration: 0.3s;
  border: 1px solid transparent;
  letter-spacing: 1px;
  min-width: 10vh;
  white-space: normal;
  font-weight: 400;
  text-align: center;
  color: #fff;
  background-color: #00bf63;
  height: 3.1em;
  margin-bottom: 2vh;
  width: 100%;
}

.button1:active {
  background-color: #2e7d32;
  transform: translateY(1px);
}

.button2 {
  display: inline-block;
  cursor: pointer;
  font-size: 1.7vh;
  line-height: 1;
  border-radius: 500px;
  transition-duration: 0.3s;
  border: 1px solid transparent;
  letter-spacing: 1px;
  min-width: 10vh;
  white-space: normal;
  font-weight: 400;
  text-align: center;
  color: #fff;
  background-color: #0751b1;
  height: 3.1em;
  margin-bottom: 1.5vh;
  width: 100%;
  justify-content: center;
}

.button2:active {
  background-color: #0d356a;
  transform: translateY(1px);
}

.back {
  display: flex; /* Tworzy kontener flex, który pozwala na elastyczne pozycjonowanie elementów */
  align-items: center; /* Wyrównuje elementy pionowo do środka kontenera */
  color: #007aff;
  font-weight: 600;
  margin-left: 1vh;
  font-size: 2vh;
  cursor: pointer;
  text-decoration: none; /* Usuwa podkreślenie z hiperłączy */
}

.underline-button {
  color: #295da0;
  text-decoration: underline;
  font-weight: 500;
  border: none;
  cursor: pointer;
  background: white;
}
.text2 {
  color: #20c777;
  font-weight: 500;
  margin-bottom: 3vh;
  margin-top: 1vh;
}

.buttons {
  text-align: center;
}

.text-container {
  margin-top: 2vh;
  margin-bottom: 2vh;
  font-size: 1.7vh;
  font-weight: 400;
}

.text-container > div {
  margin-top: 0.2vh;
}

.text-organization {
  font-weight: 600;
  font-size: 1.7vh;
}

.list {
  font-size: 1.7vh;
  padding: 0.5vh 2.5vh;
  margin: 0px;
}

.list li {
  line-height: 1.5; /* Dostosuj do wysokości linii tekstu */
}

.list ::marker {
  font-size: 1vh;
}

.text1 {
  margin-bottom: 1vh;
  font-weight: 400;
  font-size: 1.6vh;
  text-align: left;
}

.list-title {
  font-weight: 700;
  font-size: 1.6vh;
  margin-bottom: 0.5vh;
  margin-top: 0;
}

.bottom {
  margin-top: 1vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
}

.loading {
  color: white;
}

.text-1 {
  font-weight: 700;
  font-size: 1.7vh;
  margin-bottom: 1vh;
}

.text-2 {
  color: #0b4693;
  font-size: 1.7vh;
  font-weight: 700;
  margin: 1.5vh 0;
}

.long-text {
  font-size: 1.8vh;
  font-weight: 400;
  text-align: justify;
  margin-bottom: 1vh;
  line-height: 20px;
}
.info-container {
  display: flex;
}

.picture {
  float: left;
  margin-right: 1.5vh;
}

.title1 {
  font-size: 1.7vh;
  color: #0751b1;
}

.text-container1 {
  font-size: 1.6vh;
  margin-bottom: 1vh;
  font-weight: 500;
  margin-left: 3vw;
}
.bottom-text {
  font-size: 1.8vh;
  margin-bottom: 1.5vh;
  line-height: 20px;
}
.vertical-line {
  height: 0.1vh;
  width: 40vw;
  background-color: #0b4693;
  margin-bottom: 1vh;
  position: absolute;
  right: 5vw;
  margin-top: -2vh;
}
@media only screen and (max-width: 400px) {
  .text-container1 {
    font-size: 1.8vh;
    font-weight: 500;
  }
  .picture {
    float: left;
    margin-right: 2.5vh;
  }
  .long-text {
    font-size: 1.7vh;
    font-weight: 400;
    text-align: justify;
    margin-bottom: 0vh;
  }
  .info-container {
    display: flex;
    align-items: center;
  }
  .bottom-text {
    font-size: 1.6vh;
  }
}
.text_1 {
  margin-top: 2vh;
  color: #0b4693;
  font-size: 1.7vh;
  font-weight: 700;
  margin-bottom: 1vh;
}
.picture1_img {
  width: 100%;
}
.text_2 {
  margin-bottom: 3vh;
  font-weight: 300;
  font-size: 2vh;
  text-align: justify;
}
.details_part1 {
  font-weight: 500;
  font-size: 1.9vh;
  margin: 1vh 0;
  font-family: "Open Sans";
}

.part2 {
  margin-bottom: 1vh;
}

.places-design {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  background: white;
  margin: 2vh 2vh;
  margin: 3vh;
  -webkit-box-shadow: 2px 2px 9px -3px rgba(66, 68, 90, 1);
  -moz-box-shadow: 2px 2px 9px -3px rgba(66, 68, 90, 1);
  box-shadow: 2px 2px 9px -3px rgba(66, 68, 90, 1);
  position: relative;
}

.footer {
  background: #3370ba;
  position: fixed;
  bottom: 0;
  height: 8vh;
  width: 100%;
  z-index: 1100;
}

.footer_buttons {
  flex-direction: row;
  display: flex;
  justify-content: space-between;
  margin-left: 7vh;
  margin-right: 7vh;
  margin-top: 1vh;
}

.footer_button {
  flex-direction: column;
  display: flex;
  align-items: center;
  font-size: 1.4vh;
  font-weight: 500;
  color: #fff;
}

.footer_pic {
  height: 30px;
}

.back_icon {
  margin-right: 1.2vh;
}

.places_organization {
  height: 100%;
  padding-bottom: 9vh;
}

.part3_places {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

.part1_text {
  margin-right: 2vh;
  font-size: 2vh;
  margin-top: 0.5vh;
  font-weight: bold;
}

.part1_places {
  display: flex;
  flex-direction: row;
  padding: 2vh 3vh;
}

.places_picture {
  height: 5vh;
  width: auto;
}

.places_line {
  width: 100%;
  height: 1px;
  background: grey;
}

.part2_text {
  color: #3e3e3e;
  font-weight: 300;
  padding: 2vh 3vh;
  font-family: "Open Sans";
}

.part2_text_list {
  font-size: 1.6vh;
  font-weight: lighter;
  padding-left: 0vh;
  font-family: "Open Sans";
  font-weight: 300;
}

.part2_text_list ul {
  list-style: none;
  padding-left: 0;
}

.part2_text_list li {
  display: flex;
  align-items: center;
}

.part2_text_list li::before {
  content: "•";
  font-size: 1.4vh;
  margin-right: 0.8em;
}

.account {
  padding: 1vh;
  gap: 1vh;
  display: flex;
  flex-direction: column;
}

.account_text {
  font-size: 2vh;
  color: #0b4693;
  font-weight: bold;
  margin-left: 10px;
}

.account_image {
  width: 100%;
}

.conversation_nav {
  width: 100%;
}

.nav_img {
  justify-self: center;
  width: 100%;
  height: 9vh;
  background: #f3f6ff;
}

.conversation_pic {
  width: 100%;
  height: 8vh;
  position: absolute;
  bottom: 9vh;
}

.conversation2 {
  text-align: right;
}

.chats {
  padding: 3vh 2vh;
}

.asd {
  text-align: center;
  top: 6%;
  width: 100%;
  z-index: 1000;
  position: fixed;
}

.search-box {
  display: inline-block;
  font-size: 1.6vh;
  border-radius: 500px;
  min-width: 10vh;
  white-space: normal;
  font-weight: bold;
  height: 2.9em;
  width: 67%;
  padding-left: 8vh;
  padding-right: 2vh;
  border: 1px solid #0751b1;
}

.button_login {
  display: inline-block;
  cursor: pointer;
  font-size: 1.7vh;
  line-height: 1;
  border-radius: 500px;
  transition-duration: 0.3s;
  border: 1px solid white;
  letter-spacing: 1px;
  min-width: 10vh;
  white-space: normal;
  font-weight: bold;
  text-align: center;
  color: #fff;
  background-color: transparent;
  height: 3.5em;
  margin-bottom: 3.5vh;
  width: 80%;
}
.button_login_screen1 {
  display: inline-block;
  cursor: pointer;
  font-size: 1.7vh;
  line-height: 1;
  border-radius: 500px;
  transition-duration: 0.3s;
  border: 2px solid #0b4693;
  min-width: 10vh;
  white-space: normal;
  font-weight: bold;
  text-align: center;
  color: #fff;
  background-color: #3370ba;
  height: 3.5em;
  width: 80%;
}

.button_login_screen2 {
  display: inline-block;
  cursor: pointer;
  font-size: 1.7vh;
  line-height: 1;
  border-radius: 500px;
  transition-duration: 0.3s;
  border: 2px solid #0b4693;
  min-width: 10vh;
  white-space: normal;
  font-weight: bold;
  text-align: center;
  color: #0b4693;
  background-color: transparent;
  height: 3.5em;
  width: 80%;
}

.login_text {
  margin-bottom: 1.5vh;
  color: white;
  font-size: 2vh;
  font-weight: 300;
  margin-left: 20%;
  margin-right: 20%;
}

.title_text {
  margin-bottom: 1.5vh;
  color: white;
  font-size: 3vh;
  font-weight: 600;
}

.login_text1 {
  margin-bottom: 5vh;
  margin-top: 2vh;
  color: #1a5aaa;
  font-weight: 500;
}

.password {
  color: #004aad;
  font-weight: bold;
}

.email {
  color: #004aad;
  font-weight: bold;
}

.forgot-password {
  color: #1a5aaa;
  margin-top: 2vh;
  font-weight: 500;
  font-size: 1.7vh;
  margin-bottom: 2vh;
}

.organization_container {
  background: white;
  z-index: 1100;
  padding-top: 1vh;
  padding-bottom: 1vh;
}

.organization_add {
  width: 90%;
  background: #1a5aaa47;
  position: relative;
  z-index: 1200;
  margin-left: 5%;
  margin-right: 5%;
}

.organization_outer {
  width: 90%;
  flex-direction: column;
  left: 5%;
  top: 4%;
  position: absolute;
  z-index: 1110;
  padding-bottom: 6vh;
}

.organization_text {
  font-size: 1.7vh;
  text-align: center;
  padding: 2vh;
}

.organization_img {
  Width:
22px;
Height:
28.13px
Top
40.91px
Left
145px
}
.organization_hashtags_container {
  display: flex; 
  flex-wrap: wrap;
  margin-left: 5%; 
  margin-right: 5%;
  margin-top: 2%;
}

.organization_hashtag {
  display: inline-block;
  cursor: pointer;
  font-size: 1.7vh;
  line-height: 2;
  border-radius: 25px;
  vertical-align: middle;
  border: 1px solid transparent;
  border-color: #0751b1;
  letter-spacing: 1px;
  text-align: center;
  color: #0751b1;
  background-color: #fff;
  height: 4vh;
  justify-content: center;
  padding: 0 10px;
  margin-right: 5px;
  margin-bottom: 10px;
}

.organization_input .input_field{
  width:80%;
  height:4vh;
  font-size: 1.7vh;
  border: 0px;
}

.organization_input .input_button{
    display: inline-block;
    cursor: pointer;
    font-size: 1.7vh;
    line-height: 1;
    border-radius: 25px;
    transition-duration: 0.3s;
    border: 1px solid transparent;
    letter-spacing: 1px;
    min-width: 10px;
    white-space: normal;
    text-align: center;
    color: #fff;
    background-color: #0751b1;
    height: 3.1vh;
    margin-bottom: 1.5vh;
    width: 10%;
    justify-content: right;
}
.organization_hashtag .input_button 
{
  margin-top:2.5px;
  margin-left:5px;
  border: 1px solid transparent;
  border-color:#0751b1;
  display: inline-block;
  cursor: pointer;
  font-size: 1.5vh;
  line-height: 2.5vh;
  border-radius: 25px;
  transition-duration: 0.3s;
  min-width: 30px;
  white-space: normal;
  text-align: center;
  margin-right: 0px;
  color: white;
  background-color: #0751b1;

  margin-bottom: 1.5vh;
  width: 10%;
  justify-content: right;
}
.file_input {
  position: absolute;
  width: 0;
  height: 0;
  overflow: hidden;
  opacity: 0;
  visibility: hidden;
}
.organization_input_send .submit_button{
  display: inline-block;
  cursor: pointer;
  font-size: 1.7vh;
  line-height: 1;
  border-radius: 25px;
  transition-duration: 0.3s;
  border: 1px solid transparent;
  letter-spacing: 1px;
  min-width: 15px;
  white-space: normal;
  text-align: center;
  margin-right: 27px;
  color: #fff;
  background-color: #0751b1;
  height: 4vh;
  margin-bottom: 1.5vh;
  width: 100%;
  justify-content: center;
}
.organization_input {
  border: 1px solid;
  height: 4vh;
  width: 82%;
  margin: 3% 5%;
  font-size: 1.7vh;
  padding: 2% 4%;
  font-family: "Open Sans";
}
.organization_input_send {
  height: 4vh;
  width: 82%;
  margin: 5%;
  font-size: 1.7vh;
  padding: 2% 4%;
  font-family: "Open Sans";
}

.organization_input_bold {
  border: 3px solid;
  height: 4vh;
  width: 82%;
  margin: 2%;
  font-size: 1.7vh;
  padding: 2% 4%;
  font-family: "Open Sans";
}

.Places_img {
  background: none;
}

.places_button {
  padding: 0;
  border: none;
  background: white;
  margin-top: 3vh;
  margin-right: 3vw;
}

.part3_text {
  padding: 2vh 3vh 3vh 3vh;
}

.part3_text > div {
  font-size: 1.6vh;
  font-weight: 300;
  font-family: "Open Sans";
}

.Places_location {
  flex-direction: row;
  display: flex;
}

.Places_location > div {
  margin-top: 0.4vh;
  margin-left: 1vh;
}

.part2Text {
  font-weight: 800;
}

.background_pic {
  display: flex;
  position: fixed;
  z-index: 900;
  height: 100%;
  width: 100%;
}
.organization_input input {
  border: none;
  border-bottom: 0.4px solid #000000; /* Black underline */
  outline: none;
  width: 70%; /* Adjust width as needed */
}
.login_content {
  position: relative;
  z-index: 1001;
  text-align: center;
  flex-direction: column;
}

.Login_logo {
  margin-top: 11vh;
  margin-bottom: 1vh;
}

.containers_organiation {
  flex-direction: column;
  text-align: center;
  padding-right: 0vh;
}

.organization_screen_text {
  text-align: center;
  color: white;
  margin: 0 18vw 2vh 18vw;
}

.organization_screen_img {
  height: 28px;
  width: 28px;
}

.input_img {
  position: absolute;
  padding: 4%;
  right: 4%;
}

.Login_container {
  padding: 1vh;
  padding-top: 3vh;
  width: 90%;
  display: flex;
  flex-direction: column;
  z-index: 1010;
  position: fixed;
  background: white;
  top: 65%;
  left: 50%;
  transform: translate(-50%, -50%);
  box-sizing: border-box;
  padding-bottom: 4vh;
}

.Login_form {
  width: 90%;
  padding: 0 5%;
}

.Login_input {
  font-size: 2vh;
  padding: 1vh 0;
  border: none;
  border-bottom: 1px solid #007bff;
  width: 100%;
  margin-bottom: 2vh;
  outline: none;
}

.Login_input::placeholder {
  color: #5e95c7;
  font-family: "Open Sans";
}

.Login_buttons {
  text-align: center;
  margin-top: 1vh;
}

.Login_icon {
  position: absolute;
  z-index: 1111;
  margin-top: 4vh;
  margin-left: 3vh;
}

.Login_back {
  color: white;
  font-weight: 600;
  margin-left: 1vh;
  font-size: 2vh;
  cursor: pointer;
}

.Login_back_icon {
  height: 3vh;
  width: 3vh;
}

.FontAwesomeIcon {
  height: 2.6vh;
  width: 2.6vh;
}

.organization_input_select > div {
  border: none !important;
  
}

.css-1fdsijx-ValueContainer {
  padding: 0 !important;
  background-color: none;
}

.css-qbdosj-Input {
  margin: 0;
  padding: 0;
}

.logo-img {
  width: 100%;
  text-align: center;
  margin-top: 20px;
}

.logo-img img {
  width: 100px;
}

@media only screen and (min-width: 400px) {
  .logo-img {
    width: 100%;
    justify-content: space-between;
    align-items: center;
    display: flex;
  }
  .logo-img img {
    width: 10vw;
    height: auto;
    display: flex;
  }
}